import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const FourStyles = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 30vh;
`;

export default function fourOhFour() {
    return (
        <>
            <FourStyles>
                <p>Ooops!!! That page doesn't exist.</p>
                <Link to={'/'} className="button">go home</Link>
            </FourStyles>
        </>
    )
}